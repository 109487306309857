<script setup>
import Layout from "../../layouts/main.vue";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref } from "vue";
import DataForm from "./dataFormHanhChinh";
import { FormInstance } from "element-plus";
import { useRouter, useRoute } from "vue-router";
const rolesAccount = ref("");
// ngôn ngữ -> bắt buộc
import en from "element-plus/lib/locale/lang/en";
import vi from "element-plus/lib/locale/lang/vi";

// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import toastr from "toastr";
import LanguageService from "../../service/LanguageService";

const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const ruleFormRef = ref(FormInstance);
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
const dataDepartment = reactive({ value: [] });
let loading = ref(true); // loading bảng
const router = useRouter();
const route = useRoute();
const listType = ["Tỉnh", "Thành phố"];
const centerDialogVisible = ref(false);
let idProvince = ref("");
let idDepartment = ref("");
const checkUpdate = ref(false);
let lengthProvince = ref(0);

// function
// lấy về danh sách tỉnh
const fn_LayDanhSachTinh = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: "id,disabled,code,code3,name,type,department_id,center",
    limit: 100,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "dvhcTinh",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.province.listAsync(dataFilter, {
      cacheAge: 1,
    });
    if (response?.result) {
      console.log(" danh sách tỉnh: ", response.result);
      lengthProvince.value = response.result.length;
      let res = await changeData(response.result);
      dataTables.value = res;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const changeData = async (data) => {
  const item = data.filter((x) => x.department_id).map((x) => x.department_id);

  if (item.length > 0) {
    try {
      const response = await mushroom.department.listAsync({
        filters: "id:in:" + item.join(","),
      });
      if (response.result) {
        data.forEach((province) => {
          if (province.department_id) {
            response.result.forEach((department) => {
              if (department.id === province.department_id) {
                province.department_name = department.name;
              }
            });
          }
        });
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }
  return data;
};

// lấy danh sách đơn vị
const getDepartment = async () => {
  try {
    const response = await mushroom.department.listAsync();
    console.log("Các department thỏa mãn: %o, info: %o", response);
    if (response.result) {
      dataDepartment.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "dvhcTinh",
    query: query,
  });
};

const handleDepartment = (event) => {
  if (!event) return;
  tableRules.dataSearch.value["department_id"] = tableRules.dataSearch.value[
    "department_id"
  ]
    ? tableRules.dataSearch.value["department_id"].trim()
    : "";
};

// tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      loading.value = true;
      tableRules.dataSearch.value["code"] = tableRules.dataSearch.value["code"]
        ? tableRules.dataSearch.value["code"].trim()
        : "";
      tableRules.dataSearch.value["type"] = tableRules.dataSearch.value["type"]
        ? tableRules.dataSearch.value["type"].trim()
        : "";
      tableRules.dataSearch.value["name"] = tableRules.dataSearch.value["name"]
        ? tableRules.dataSearch.value["name"].trim()
        : "";

      // handleDepartment();

      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      tableRules.offset = 0;
      tableRules.page = 1;
      fn_LayDanhSachTinh();
    } else return false;
  });
};

const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  fn_LayDanhSachTinh();
};

const handleClick = (id) => {
  localStorage.setItem("idProvince", id);
};

const showEditDepartment = (department_id, id) => {
  idDepartment.value = "";
  centerDialogVisible.value = true;
  if (department_id) {
    dataDepartment.value.forEach((item) => {
      if (item.id === department_id) {
        idDepartment.value = item.id;
      }
    });
    checkUpdate.value = true;
  } else {
    checkUpdate.value = false;
  }
  idProvince.value = id;
};

const editDepartment = async () => {
  centerDialogVisible.value = false;

  const province_object = {
    id: idProvince.value,
    department_id:
      idDepartment.value.length > 0
        ? dataDepartment.value.filter((x) => x.id === idDepartment.value)[0].id
        : null,
  };

  if (idProvince.value) {
    try {
      const response = await mushroom.province.partialUpdateAsync(
        province_object
      );
      if (response.result == idProvince.value) {
        if (checkUpdate.value) {
          toastr.success(
            LanguageService?.[lang.value].toastr_edit_unit_success ??
              "toastr_edit_unit_success"
          );
        } else {
          if (idDepartment.value.length > 0) {
            toastr.success(
              LanguageService?.[lang.value].toastr_add_unit_success ??
                "toastr_add_unit_success"
            );
          } else {
            toastr.warning(
              LanguageService?.[lang.value].toastr_add_unit_fail ??
                "toastr_add_unit_fail"
            );
          }
        }
        fn_LayDanhSachTinh();
      } else console.log("Dữ liệu không thay đổi");
    } catch (error) {
      console.error("Có lỗi: %o", error);
    }
  } else {
    if (checkUpdate.value) {
      toastr.warning(
        LanguageService?.[lang.value].toastr_edit_unit_fail ??
          "toastr_edit_unit_fail"
      );
    } else {
      toastr.warning(
        LanguageService?.[lang.value].toastr_add_unit_fail ??
          "toastr_add_unit_fail"
      );
    }
  }
};

const fn_hashUrl = () => {
  if (route.query.limit) {
    tableRules.limit = parseInt(route.query.limit);
  }
  if (route.query.page) {
    tableRules.page = parseInt(route.query.page);
  }
  if (route.query.limit && route.query.page) {
    tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  }
  if (route.query.sort) {
    tableRules.sort = route.query.sort;
    tableRules.defaultSort[0] = tableRules.sort;
  }
  if (tableRules.sort.indexOf("-") != -1) {
    tableRules.defaultSort[1] = "descending";
  } else {
    tableRules.defaultSort[1] = "ascending";
  }
  if (route.query.filters) {
    tableRules.filters = route.query.filters;
    // string to object
    tableRules.dataSearch.value = MethodService.stringToObject(
      tableRules.filters
    );
  }
  if (route.query.showFormSearch) {
    tableRules.showFormSearch = route.query.showFormSearch;
  }
};

onMounted(async () => {
  const response = await mushroom.$auth.meAsync();
  rolesAccount.value = response.result.roles[0];
  fn_hashUrl();
  fn_LayDanhSachTinh();
  getDepartment();
});
</script>

<template>
  <Layout>
    <div class="card">
      <div
        class="card-header"
        style="display: flex; justify-content: space-between"
      >
        <h3 class="text-uppercase mb-0">{{ $t("t-dvhc-tinh") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-config-provider
                  :locale="lang_web.showLang == 'vi' ? vi : en"
                >
                  <el-form
                    ref="ruleFormRef"
                    :model="tableRules.dataSearch.value"
                    :rules="validForm.value"
                    label-position="top"
                    @submit.prevent
                  >
                    <b-row>
                      <b-col>
                        <el-form-item
                          :label="`${$t('t-code-province')}`"
                          prop="code"
                        >
                          <el-input
                            clearable
                            v-model="tableRules.dataSearch.value['code']"
                            :placeholder="`${$t('t-place-code-province')}`"
                            type="number"
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item
                          :label="`${$t('t-type-province')}`"
                          prop="type"
                        >
                          <el-select
                            v-model="tableRules.dataSearch.value['type']"
                            filterable
                            clearable
                            :placeholder="`${$t('t-place-type-province')}`"
                            :no-match-text="$t('t-match-data')"
                            :no-data-text="$t('t-no-data')"
                          >
                            <el-option
                              v-for="item in listType"
                              :key="item"
                              :label="item"
                              :value="item"
                            />
                          </el-select>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item
                          :label="`${$t('t-province-name')}`"
                          prop="name"
                        >
                          <el-input
                            clearable
                            v-model="tableRules.dataSearch.value['name']"
                            :placeholder="`${$t('t-place-province')}`"
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item :label="`${$t('t-department')}`">
                          <el-select
                            v-model="
                              tableRules.dataSearch.value['department_id']
                            "
                            filterable
                            clearable
                            :placeholder="`${$t('t-choose-department')}`"
                            :no-match-text="$t('t-match-data')"
                            :no-data-text="$t('t-no-data')"
                            @change="handleDepartment"
                          >
                            <el-option
                              v-for="item in dataDepartment.value"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-form-item>
                      </b-col>
                    </b-row>
                    <div class="text-center">
                      <a
                        class="btn btn-soft-secondary btn-border mx-3"
                        style="width: 80px"
                        @click="fn_showFormTimKiem()"
                      >
                        {{ $t("t-hide") }}
                      </a>
                      <button
                        class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                        style="width: 100px"
                        native-type="submit"
                        @click="fn_TimKiem(ruleFormRef)"
                      >
                        {{ $t("t-search") }}
                      </button>
                    </div>
                  </el-form>
                </el-config-provider>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <span
              class="el-pagination__total is-first"
              style="font-weight: 400; font-size: 12px"
            >
              {{ `${$t("t-total")} ${lengthProvince} ` }}</span
            >
          </div>
          <el-config-provider :locale="lang_web.showLang == 'vi' ? vi : en">
            <el-table
              size="small"
              border
              stripe
              :data="dataTables.value"
              :style="`width: 100%, ov; height: calc(100vh - 247px - ${heightSearch})`"
              v-loading="loading"
              :empty-text="`${$t('t-no-data')}`"
              @sort-change="fn_tableSortChange"
              :default-sort="{
                prop: tableRules.defaultSort[0],
                order: tableRules.defaultSort[1],
              }"
            >
              <el-table-column
                prop="code"
                :label="`${$t('t-code-province')}`"
                align="center"
                width="140"
                :sortable="true"
              ></el-table-column>
              <el-table-column
                prop="type"
                :label="`${$t('t-type-province')}`"
                width="140"
                align="center"
                :sortable="true"
                sort-by="Thành phố"
              ></el-table-column>
              <el-table-column
                prop="name"
                :label="`${$t('t-province-name')}`"
                align="center"
                :sortable="true"
              ></el-table-column>
              <el-table-column
                prop="department_name"
                :label="`${$t('t-department')}`"
                align="center"
                :sortable="true"
              ></el-table-column>
              <el-table-column
                :label="`${$t('t-show-district')}`"
                width="140"
                align="center"
              >
                <template #default="scope">
                  <div class="btn-group-thao-tac">
                    <el-tooltip
                      :content="`${$t('t-detail-huyen')}`"
                      effect="light"
                      placement="top"
                      :enterable="false"
                    >
                      <button
                        type="button"
                        class="btn btn-soft-info waves-effect waves-light btn-sm"
                        @click="handleClick(scope.row.id)"
                      >
                        <router-link
                          class="link"
                          to="/dvhc/huyen"
                          style="height: 11.5px; line-height: 12px"
                        >
                          <i class="ri-eye-line"></i>
                        </router-link>
                      </button>
                    </el-tooltip>
                    <el-tooltip
                      :content="`${$t('t-assign-departments')}`"
                      effect="light"
                      placement="top"
                      :enterable="false"
                      v-if="rolesAccount === 'Admin'"
                    >
                      <button
                        type="button"
                        class="btn btn-soft-success waves-effect waves-light btn-sm"
                        @click="
                          showEditDepartment(
                            scope.row.department_id,
                            scope.row.id
                          )
                        "
                      >
                        <i class="ri-edit-box-line"></i>
                      </button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-config-provider>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="centerDialogVisible"
      :title="`${$t('t-assign-departments')}`"
      width="30%"
      center
    >
      <el-select
        v-model="idDepartment"
        filterable
        clearable
        :placeholder="`${$t('t-place-departments')}`"
        :no-match-text="$t('t-match-data')"
        :no-data-text="$t('t-no-data')"
      >
        <el-option
          v-for="item in dataDepartment.value"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">{{
            $t("t-back")
          }}</el-button>
          <el-button type="primary" @click="editDepartment">{{
            $t("swal_accept")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </Layout>
</template>

<style scoped>
a {
  color: unset;
}
.el-table {
  height: calc(100vh - 247px);
}
</style>
